import {
	tertiaryColor,
	ltGtMediaQuery,
	smMediaQuery,
	gtMdMediaQuery,
	gtSmMediaQuery,
} from 'root/src/client/web/commonStyles'

import modalStyle from 'root/src/client/web/list/modalStyle'

const createNewLink = {
	fontSize: 18,
	letterSpacing: 1,
	fontWeight: 'bold',
	textDecoration: 'underline',
	'&:hover': {
		color: tertiaryColor,
	},
}

export const cardListStyle = {
	nothingHeight: {
		height: 525,
		display: 'flex',
		alignItems: 'center',
		'@media (max-width: 1024px)': {
			height: 301,
		},
		'@media (max-width: 414px)': {
			height: 373,
		},
	},
	nothingTextContainer: {
		fontSize: 20,
	},
	createNewLink,
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	goTopContainer: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		marginBottom: 20,
		fontWeight: 'bold',
		fontSize: 18,
	},
	paddingOffset: {
		justifyContent: 'center',
		padding: [[20, 0]],
		[ltGtMediaQuery]: {
			padding: [[10, 0]],
		},
	},
	...modalStyle,
}

export const clipCardListStyle = {
	paddingOffset: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 50,
		[gtSmMediaQuery]: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 450px)',
		},
		[gtMdMediaQuery]: {
			gridGap: 50,
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 350px)',
		},
	},
	clipCardContainer: {
		width: 450,
		height: 250,
		[gtSmMediaQuery]: {
			width: '100%',
		},
		[gtMdMediaQuery]: {
			width: '100%',
		},
	},
	icn: {
		width: 10,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	loaderContainer: {
		marginTop: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
}

export const videoCardListStyle = {
	sliderItemsStyle: {
		padding: [[20, 0]],
	},
	root: {
		margin: [[45, 0]],
		minWidth: 420,
		[smMediaQuery]: {
			minWidth: 600,
		},
		[ltGtMediaQuery]: {
			margin: [[30, 0]],
		},
		'@media (max-width: 420px)': {
			minWidth: 320,
		},
	},
	buttonWrapper: {
		width: '100%',
		height: 50,
		margin: [[30, 0, 0]],
	},
	buttonContainer: {
		width: 172,
		height: 36,
		margin: [[0, 'auto']],
	},
	buttonText: {
		[ltGtMediaQuery]: {
			fontSize: 14,
		},
	},
}

export const SliderCardsListStyle = {
	root: {
		padding: [[60, 0]],
		position: 'relative',
		width: '100%',
	},
	sliderWrapper: {
		width: '100%',
		position: 'relative',
		padding: 0,
	},
}

export const CategorizedCardListStyle = {
	category: {
		marginTop: 30,
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
}
