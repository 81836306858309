import { useEffect, useRef } from 'react'

export default (callback, delay) => {
	const savedCallback = useRef()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		const tick = () => {
			savedCallback.current()
		}
		if (delay === null) {
			return
		}
		const id = setInterval(tick, delay)

		// eslint-disable-next-line consistent-return
		return () => clearInterval(id)
	}, [delay])
}
