import range from 'ramda/src/range'
import React, { memo } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles'
import { projectCardStyle } from './listItemComponents/style'

const UnStyledLoadingProgress = memo(({ loading, classes, paddingTop = 75, fontSize = 14 }) => {
	if (!loading) { return null }
	return (
		<div className={classes.loadingContainer}>
			<div className={classes.loadingBlock} style={{ paddingTop }}>
				<div className={classes.loadingText} style={{ fontSize }}>Loading...</div>
				<LoadingCircles />
			</div>
			{range(0, 7).map(index => (
				<section key={index} className={classNames(classes.cardRoot, classes.cardRootPlaceholder, classes[`hiddenPlaceholder-${index}`])}>
					<div className="flex layout-column">
						<div>
							<div className={classNames(classes.cardHeader, 'layout-row layout-align-start-center header-container')}>
								<div className={classNames(
									classes.headerContainer, classes.headerContainerPlaceholder,
								)}
								>
									<div className={classes.shareIconPlaceholder}>
										<IconButton
											aria-label="More"
											aria-haspopup="true"
											className={classes.iconColor}
										>
											<ShareIcon />
										</IconButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			))}
		</div>
	)
})

export default withStyles(projectCardStyle, { name: 'MuiLoadingProgress' })(UnStyledLoadingProgress)
