import { memo } from 'react'
import pipe from 'ramda/src/compose'
import __ from 'ramda/src/__'
import length from 'ramda/src/length'
import map from 'ramda/src/map'
import equals from 'ramda/src/equals'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import { ternary } from 'root/src/shared/util/ramdaPlus'
import ProjectCard from 'root/src/client/web/list/listItemComponents/project/ProjectCard'
import classNames from 'classnames'
import LoadingProgress from 'root/src/client/web/list/LoadingProgress'
import SubTitle from 'root/src/client/web/typography/SubTitle'
import withStyles from '@material-ui/core/styles/withStyles'
import { trackWindowScroll } from 'react-lazy-load-image-component'
import NoMatchDareStab from 'root/src/client/web/list/NoMatchDareStab'
import InfiniteScroller from 'root/src/client/web/list/util/InfiniteScroller'
import { COMMON_REF_ID } from 'root/src/shared/descriptions/modules/stepperHeader'
import MaxWidthContainer from 'root/src/client/web/base/MaxWidthContainer'
import dynamic from 'next/dynamic'
import { cardListStyle } from 'root/src/client/web/list/listComponents/style'
import { RecordProvider } from 'root/src/client/web/contexts/recordContext'

const EmbeddedModule = dynamic(() => import('root/src/client/web/embedded/EmbeddedModule'))

const styles = {
	...cardListStyle,
	embeddedContentWrapper: {
		width: '80%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
}

const CardList = memo(({
	list, currentPage, hasMore, getNextPage, classes, embeddedContent,
	moduleId, pageItemLength, visibleLoadingBlock, listSubtitle,
}) => (
	<>
		{embeddedContent && (
			<div className={classes.embeddedContentWrapper}>
				<div>
					<EmbeddedModule {...embeddedContent} />
				</div>
			</div>
		)}
		<MaxWidthContainer>
			{listSubtitle && (
				<SubTitle className={classes.subtitle}>{listSubtitle}</SubTitle>
			)}
			{InfiniteScroller({
				currentPage,
				hasMore,
				getNextPage,
				moduleId,
				pageItemLength,
			})(
				ternary(
					pipe(length, equals(__, 0))(list) && !visibleLoadingBlock,
					<NoMatchDareStab />,
					<div
						className={classNames(
							classes.paddingOffset,
							'layout-row layout-wrap',
						)}
						id={COMMON_REF_ID}
					>
						{map(
							recordId => (
								<RecordProvider
									key={recordId}
									recordId={recordId}
								>
									<ProjectCard key={recordId} recordId={recordId} />
								</RecordProvider>
							),
							list,
						)}
						<LoadingProgress loading={visibleLoadingBlock} />
					</div>,
				),
			)}
		</MaxWidthContainer>
	</>
), comparePropsFn(['list', 'currentPage', 'hasMore', 'pageItemLength', 'visibleLoadingBlock']))

export default withStyles(styles, { name: 'MuiCardList' })(trackWindowScroll(CardList))
