import React from 'react'
import isNil from 'ramda/src/isNil'
import InfiniteScroll from 'react-infinite-scroller'

const styles = {
	width: '100%',
}

const InfiniteScrollerHof =	({
	moduleId,
	hasMore,
	pageItemsCount,
	getNextOffsetPage,
	pageItemLength,
	getNextPage,
	currentPage,
	allPage,
}) => (ChildComponent) => {
	const getNextPageFn = isNil(hasMore) ? getNextOffsetPage : getNextPage
	if (pageItemLength || pageItemsCount) {
		return (
			<InfiniteScroll
				pageStart={0}
				loadMore={() => getNextPageFn(moduleId, hasMore, currentPage, pageItemsCount)}
				hasMore={
					isNil(hasMore) ? allPage > currentPage * pageItemsCount : hasMore
				}
				style={styles}
			>
				{ChildComponent}
			</InfiniteScroll>
		)
	}
	return ChildComponent
}

export default InfiniteScrollerHof
