import React, { memo } from 'react'
import Link from 'root/src/client/web/base/Link'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import {
	CREATE_PROJECT_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { noMatchDareStyle } from 'root/src/client/web/list/style'

const noMatchDareStab = memo(({ classes }) => (
	<div
		className={classNames(
			classes.paddingOffset,
			'layout-row layout-wrap',
			classes.nothingHeight,
		)}
	>
		<div className={classes.nothingTextContainer}>
			No Dares match your criteria, want to
			<span>{' '} </span>
			<Link routeId={CREATE_PROJECT_ROUTE_ID}>
				<span className={classes.createNewLink}>make one</span>
			</Link>
			?
		</div>
	</div>
))

export default withStyles(noMatchDareStyle, { name: 'MuinoMatchDareStab' })(noMatchDareStab)
